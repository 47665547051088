<template>
  <div class="d-flex overflow-hidden">
    <div>
      <div
        class="my-2 mx-2 mx-lg-4 circle-outline bg-white border border-dark border-1 d-flex justify-content-center align-items-center"
      >
        <i class="ri-custom-small text-secondary" :class="icon"></i>
      </div>
      <div v-show="hide_dotted" class="mx-auto vertical-divider-dotted"></div>
    </div>
    <div class="p-4">
      <h5 class="text-dark fw-bold">{{ step }} {{ title }}</h5>
      <h6 class="text-dark" v-html="description"></h6>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['step', 'title', 'icon', 'description', 'hide_dotted'],
}
</script>

<style></style>
