<template>
  <div>
    <div class="container" fluid="lg">
      <div class="row">
        <div class="col-12 p-2">
          <h4>{{ $t('service_procedure') }}</h4>
        </div>
        <div v-for="(service_procedure, index) in service_procedures" :id="index + 1" :key="index" class="col-12 p-0">
          <circle-outline-content
            :icon="service_procedure.icon"
            :step="service_procedure.step"
            :title="service_procedure.title"
            :description="service_procedure.description"
            :hide_dotted="index != service_procedures.length - 1"
          ></circle-outline-content>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import CircleOutlineContent from '@/components/CircleOutlineContent.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Footer, CircleOutlineContent },
  data() {
    return {
      service_procedures: [
        {
          step: this.$t('step_one'),
          title: this.$t('fill_in_online_application') + ' + ' + this.$t('upload_documents'),
          description: this.$t('fill_in_online_application_description') + this.$t('upload_documents_description'),
          icon: 'ri-file-list-2-line',
          icon_color: 'text-primary-50',
          bg_color: 'bg-primary-50',
          circle_color: null,
          desktop_circle_color: null,
        },
        {
          step: this.$t('step_two'),
          title: this.$t('book_online_interview'),
          description: this.$t('book_online_interview_description'),
          icon: 'ri-calendar-line',
          icon_color: 'text-primary-70',
          bg_color: 'bg-primary-70',
          circle_color: 'bg-white',
          desktop_circle_color: 'bg-primary-60',
        },
        {
          step: this.$t('step_three'),
          title: this.$t('visit_branch') + ' + ' + this.$t('receive_funds'),
          description: this.$t('visit_branch_description') + this.$t('receive_funds_description'),
          icon: 'ri-building-line',
          icon_color: 'text-primary-80',
          bg_color: 'bg-primary-80',
          circle_color: 'bg-white',
          desktop_circle_color: 'bg-primary-70',
        },
        // {
        //   step: "step_six",
        //   title: "easy_repayments",
        //   description: "easy_repayments_description",
        //   icon: "ri-heart-line",
        //   icon_color: "text-primary",
        //   bg_color: "bg-primary",
        //   circle_color: "bg-white",
        //   desktop_circle_color: "bg-primary-90",
        // },
      ],
    }
  },
  created() {
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
